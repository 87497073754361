@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;300;400;500;700&display=swap");

body {
  margin: 0;

  font-family: "Roboto Mono", monospace;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
  cursor: default !important;
}

@keyframes beacon {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: inline-block;
    opacity: 0;
  }

  100% {
    display: inline-block;
    opacity: 1;
  }
}
